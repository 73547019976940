import Add from '@icons/add.svg'
import Download from '@icons/download.svg'
import Edit from '@icons/edit.svg'
import FactCheck from '@icons/fact_check.svg'
import Folder from '@icons/folder.svg'
import History from '@icons/history.svg'
import Upload from '@icons/upload.svg'
import UploadFile from '@icons/upload_file.svg'
import { useFlag } from '@unleash/proxy-client-react'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AttachmentForm from 'src/components/Images/AttachmentForm'
import useProjectId from 'src/components/hooks/useProjectId'
import SystemForm from 'src/components/system/SystemForm'
import SystemSyntaxHierarchyButtons from 'src/components/system/SystemSyntax/SystemSyntaxHierarchyButtons'
import { ImportContext } from 'src/context/ImportContext/ImportContext'
import useBackgroundJob from 'src/hooks/useBackgroundJob'
import FileContainerInline from 'src/page/system/FileContainersInlineSelector'
import SelectTestSystemGroupList from 'src/page/system/SelectTestSystemGroupList'
import { ISystem } from 'src/service/OrgTypes'
import { syncObjectsOmega } from 'src/service/ProjectService'
import { downloadSystemAttachments } from 'src/service/SystemService'
import { styleClass } from 'src/ui-elements/Table/TableButtons'
import Button from 'src/ui-elements/button/Button'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { openFileExternal } from 'src/utility/utils'

interface IActionButtonProps {
  selectedSystems: ISystem[]
  setSelectedLevels: (levels: number[]) => void
  openCreateModal: () => void
  setImportModal: (show: boolean) => void
  setShowImportHistoryModal: (show: boolean) => void
  writeAccess: boolean
  testSystemGroupId?: number
  isReadOnly?: boolean
  systemSettingId?: number
  appliesToLevels?: number[]
  reload: () => void
}
const SystemTableActionButtons: FC<IActionButtonProps> = ({
  selectedSystems,
  setSelectedLevels,
  openCreateModal,
  setImportModal,
  setShowImportHistoryModal,
  writeAccess,
  testSystemGroupId,
  isReadOnly,
  systemSettingId,
  appliesToLevels = [],
  reload,
}) => {
  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const projectId = useProjectId()

  const { setTaskId, task, taskComplete } = useBackgroundJob('download')
  const omegaSyncEnabled = useFlag('OmegaSync')
  const { actions } = React.useContext(ImportContext)

  const [showMassEditModal, setShowMassEditModal] = useState(false)
  const [showUploadAttachment, setShowUploadAttachment] = useState(false)
  const [showEditFileContainer, setShowEditFileContainer] = useState(false)
  const [showEditTestSystemGroup, setShowEditTestSystemGroup] = useState(false)

  useEffect(() => {
    if (taskComplete) {
      openFileExternal(task?.data?.download_url)()
    }
  }, [task, taskComplete])

  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const onBulkDownload = async () => {
    const attachedFiles = selectedSystems.flatMap((system) => system.images)

    if (attachedFiles.length === 0) {
      showAlert('warning', 'item_not_found', t('item_selected_has_no_document'))
      return
    }

    const downloadJob = await downloadSystemAttachments(
      projectId,
      selectedSystems.map((system) => system.id),
    )

    setTaskId(downloadJob.id)
    addAlert({
      type: 'info',
      title: t('download_preparing'),
      description: t('download_preparing_description'),
    })
  }

  if (testSystemGroupId || isReadOnly || !systemSettingId)
    return (
      <>
        {omegaSyncEnabled && writeAccess && (
          <Button
            title={t('synchronize_omega')}
            type={Button.ButtonType.SECONDARY}
            className={styleClass.actionButton}
            disabled={!selectedSystems.length}
            onClick={async () =>
              actions.addImportTask(
                await syncObjectsOmega(
                  projectId,
                  selectedSystems.map((system) => system.id),
                ),
              )
            }
          >
            <img src="/icons/omega.png" className="w-6 h-6" />
          </Button>
        )}
      </>
    )
  return (
    <div className="flex items-center">
      {systemSettingId && (
        <SystemSyntaxHierarchyButtons
          systemSettingId={systemSettingId}
          appliesToLevels={appliesToLevels}
          setSelectedLevels={setSelectedLevels}
        />
      )}
      {writeAccess && (
        <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
          <Add className="fill-white text-xl" />
          {t('new_system')}
        </Button>
      )}
      {writeAccess && (
        <Button
          title={t('import')}
          type={Button.ButtonType.SECONDARY}
          className={'p-1 w-8 h-8'}
          onClick={() => setImportModal(true)}
        >
          <Upload className="text-xl" />
        </Button>
      )}
      <Button
        title={t('show_import_history')}
        type={Button.ButtonType.SECONDARY}
        className={styleClass.actionButton}
        onClick={() => setShowImportHistoryModal(true)}
      >
        <History className={'text-xl'} />
      </Button>
      {writeAccess && (
        <ButtonWithDropdownMenu
          disabled={!selectedSystems.length}
          title={t('edit')}
          type={Button.ButtonType.SECONDARY}
          items={[
            {
              title: t('edit_details'),
              icon: <Edit className="text-md mr-1" />,
              onClick: () => setShowMassEditModal(true),
            },
            {
              title: t('edit_documents'),
              icon: <Folder className="text-md mr-1" />,
              onClick: () => setShowEditFileContainer(true),
            },
            {
              title: t('edit_test_system_groups'),
              icon: <FactCheck className="text-md mr-1" />,
              onClick: () => setShowEditTestSystemGroup(true),
            },
            {
              title: t('upload_type', { type: t('attachment') }),
              icon: <UploadFile className="text-md mr-1" />,
              onClick: () => setShowUploadAttachment(true),
            },
          ]}
          className={styleClass.actionButton}
        >
          <Edit className="text-xl" />
        </ButtonWithDropdownMenu>
      )}
      {omegaSyncEnabled && writeAccess && (
        <Button
          title={t('synchronize_omega')}
          type={Button.ButtonType.SECONDARY}
          className={styleClass.actionButton}
          disabled={!selectedSystems.length}
          onClick={async () =>
            actions.addImportTask(
              await syncObjectsOmega(
                projectId,
                selectedSystems.map((system) => system.id),
              ),
            )
          }
        >
          <img src="/icons/omega.png" className="w-6 h-6" />
        </Button>
      )}
      <Button
        disabled={!selectedSystems.length}
        title={t('download_type', { type: t('attachment') })}
        className={styleClass.actionButton}
        type={Button.ButtonType.SECONDARY}
        onClick={onBulkDownload}
      >
        <Download className="text-xl" />
      </Button>
      {showMassEditModal && (
        <SystemForm
          key={'system-form'}
          show={showMassEditModal}
          systemSettingId={systemSettingId ?? -1}
          projectId={projectId}
          systemId={
            selectedSystems.length === 1 ? selectedSystems[0].id : undefined
          }
          systemIds={
            selectedSystems.length > 1
              ? selectedSystems.map((system) => system.id)
              : undefined
          }
          closeModal={() => {
            setShowMassEditModal(false)
            reload()
          }}
        />
      )}
      <AttachmentForm
        key={'attachment-form'}
        show={showUploadAttachment}
        parentType={'System'}
        parentIds={selectedSystems.map((system) => system.id)}
        projectId={projectId}
        onClose={() => {
          setShowUploadAttachment(false)
          reload()
        }}
      />
      {showEditFileContainer && (
        <FileContainerInline
          systemIds={selectedSystems.map((system) => system.id)}
          reload={() => {
            reload()
            setShowEditFileContainer(false)
          }}
        />
      )}
      {showEditTestSystemGroup && (
        <SelectTestSystemGroupList
          systemIds={selectedSystems.map((system) => system.id)}
          reload={() => {
            reload()
            setShowEditTestSystemGroup(false)
          }}
        />
      )}
    </div>
  )
}

export default SystemTableActionButtons
