import Circle from '@icons/circle.svg'
import Workspaces from '@icons/workspaces.svg'
import { useQueryClient } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import * as React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import AttachmentIcon from 'src/components/Images/AttachmentsIcon'
import {
  CreatedByColumn,
  DisciplinesColumnEditable,
  SystemFmiColumn,
  SystemFmiGroupColumnEditable,
  SystemMmiColumnEditable,
  SystemStatusColumnEditable,
  SystemTypeColumn,
  SystemTypeUniqueColumn,
  TestSystemGroupsColumn,
  TestWorkGroupsColumn,
  UpdatedByColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import {
  ContractsColumn,
  LocationColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  ItemSelect,
  ItemIdSelect,
} from 'src/components/system/system-table/SystemTable'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useSystemMetaData } from 'src/query/systems/syntaxMetadata'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  IDiscipline,
  IImage,
  IStatus,
  ISystem,
  ISystemType,
} from 'src/service/OrgTypes'
import {
  editSystem,
  getProjectSystemFMIGroups,
  getProjectSystemMMIs,
  updateSystemDisciplines,
} from 'src/service/SystemService'

import { getStatusesForType } from 'src/service/SystemStatusService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import {
  booleanColumn,
  dateColumn,
  editableTextColumn,
  multiValueListSelectorColumn,
} from 'src/ui-elements/Table/Columns'
import { filterType } from 'src/ui-elements/list/ListTypes'

import { TDropDownType } from 'src/ui-elements/page-display/inline-components/IDropDown'
import { classNames } from 'src/utility/utils'

const styleClass = {
  root: classNames('md_w-full', 'flex', 'flex-col'),
  inputGroup: classNames('w-full', 'flex', 'row'),
  recordIdText: (hasChildren: boolean) =>
    classNames(hasChildren ? 'font-bold' : ''),
}

interface ISystemTableColumnsProps {
  writeAccess: boolean
  setFileContainerSelect?: (select?: ItemIdSelect) => void
  setTestSystemGroupSelect?: (select?: ItemIdSelect) => void
  parentSystemId?: number
  testSystemGroupId?: number
  systemSettingId?: number
  isSystemList?: boolean
}

export const useSystemTableColumns = ({
  writeAccess,
  systemSettingId,
  testSystemGroupId,
  parentSystemId,
  setFileContainerSelect,
  setTestSystemGroupSelect,
}: ISystemTableColumnsProps) => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()
  const projectContext = useContext(ProjectContext)
  const { data: metaData } = useSystemMetaData(systemSettingId)
  const isInfrastructure =
    projectContext.state.currentProject.is_infrastructure_project
  const { t } = useTranslation()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['systemListFiltered'] })
  }
  const onFieldChange = (
    field: string,
    key: number,
    value: string | number | boolean,
  ) => {
    const system = { id: key, [field]: value }
    editSystem(system).finally(reload)
  }

  const updateFMIGroup = (id: number, fmiGroupId: number) => {
    onFieldChange('system_fmi_group_id', id, fmiGroupId)
  }

  const updateStatus = (id: number, statusId: number) => {
    onFieldChange('system_status_id', id, statusId)
  }

  const getSystemStatuses = async (): Promise<IStatus[]> => {
    return await getStatusesForType(projectId, 'system')
  }
  const updateMMI = (id: number, mmiId: number) => {
    onFieldChange('system_mmi_id', id, mmiId)
  }

  const onShowFileContainerSelect = (
    fileContainerIds: number[],
    id: number,
  ) => {
    setFileContainerSelect?.({
      itemIds: fileContainerIds,
      systemIds: [id ?? -1],
    })
  }

  const onShowTestSystemGroupSelect = (
    e: React.MouseEvent,
    data: ItemSelect,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setTestSystemGroupSelect?.({
      ...data,
      itemIds: data.items.map((item) => item.id),
    })
  }

  const onUpdateDisciplines = (systemId: number, dIds: TDropDownType[]) => {
    const ids = dIds.filter((id) => id !== undefined) as number[]
    updateSystemDisciplines(projectId, systemId, ids).then(() => {
      reload()
    })
  }

  const getDisciplines = async (): Promise<IDiscipline[]> => {
    return await getProjectDisciplines(projectId)
  }

  const updateMetaFiled = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }

  const userDefinedAttributesColumns = metaData
    ? userDefinedColumns(
        metaData,
        updateMetaFiled,
        'meta_data',
        true,
        false,
        !writeAccess,
      )
    : []

  const legacyColumns = [
    {
      name: 'system_number',
      id: 'record_id',
      size: '200',
      dataField:
        'record_id||parent_system||parent_system_id||is_valid||has_children',
      sortingField: 'record_id',
      filter: [],
      filterDataField: 'record_id',
      filterType: filterType.TEXT,
      cell: (data: {
        record_id: string
        parent_system?: ISystem
        parent_system_id?: number
        is_valid: boolean
        has_children: boolean
      }) => (
        <span className={'truncate'}>
          <div className={'flex items-center pl-1'}>
            <div className="pr-1">
              {data.has_children ? (
                <Workspaces
                  className={twMerge(
                    data.has_children ? 'text-base' : 'text-xs',
                  )}
                />
              ) : (
                <Circle
                  className={twMerge(
                    data.has_children ? 'text-sm' : 'text-xxs',
                  )}
                />
              )}
            </div>
            <div>
              {
                <p
                  className={styleClass.recordIdText(
                    !!testSystemGroupId ||
                      !data.parent_system_id ||
                      data.parent_system_id ===
                        parseInt(`${parentSystemId}`, 10),
                  )}
                >
                  {data.record_id}
                </p>
              }
            </div>
          </div>
        </span>
      ),
    },
    SystemStatusColumnEditable(updateStatus, getSystemStatuses, !writeAccess),
    SystemTypeColumn(systemSettingId, false),
    SystemTypeUniqueColumn(),
    {
      name: 'system_type_description',
      id: 'system_type_description',
      size: '400',
      dataField: 'system_type',
      filterDataField: 'system_type.description',
      filter: [],
      filterType: filterType.TEXT,
      cell: (systemType?: ISystemType) => (
        <span className="truncate px-1">{systemType?.description ?? ''}</span>
      ),
    },

    {
      name: 'tasks_done_total',
      size: '175',
      id: 'action',
      enableColumnFilter: false,
      enableSorting: false,
      dataField: 'done_tasks||expired_tasks||total_tasks',
      cell: (system: {
        done_tasks: number
        expired_tasks: number
        total_tasks: number
      }) => {
        return (
          <span
            className={classNames(
              system.expired_tasks > 0 ? 'text-red-two' : '',
              'px-1',
            )}
          >
            {system.done_tasks}/{system.total_tasks}
          </span>
        )
      },
    },
    {
      size: '150',
      id: 'attachment',
      name: 'attachment',
      dataField: 'images',
      filterDataField: 'images->name',
      enableColumnFilter: true,
      filterType: filterType.NUMBER,
      enableSorting: true,
      cell: (images: IImage[]) => <AttachmentIcon attachments={images} />,
    },
    SystemMmiColumnEditable(
      updateMMI,
      () => getProjectSystemMMIs(projectId),
      false,
      !writeAccess,
    ),
    SystemFmiColumn(),
    SystemFmiGroupColumnEditable(
      updateFMIGroup,
      () => getProjectSystemFMIGroups(projectId),
      false,
      !writeAccess,
    ),

    TestSystemGroupsColumn(
      writeAccess ? onShowTestSystemGroupSelect : undefined,
      !isInfrastructure,
    ),
    TestWorkGroupsColumn(!isInfrastructure),
    DisciplinesColumnEditable(
      getDisciplines,
      onUpdateDisciplines,
      false,
      !writeAccess,
    ),
    ContractsColumn(),
    LocationColumn('room'),
    ...userDefinedAttributesColumns,
    CreatedByColumn(),
    UpdatedByColumn(),
  ]

  const newColumns = [
    editableTextColumn(
      'name',
      { name: capitalize(t('system_name')) },
      (key, value) => onFieldChange('name', +key, value),
      !writeAccess,
    ),
    editableTextColumn(
      'description',
      { name: capitalize(t('description')) },
      (key, value) => onFieldChange('description', +key, value),
      !writeAccess,
    ),
    editableTextColumn(
      'location',
      { name: capitalize(t('location')) },
      (key, value) => onFieldChange('location', +key, value),
      !writeAccess,
    ),
    booleanColumn(
      'is_in_bim',
      { name: capitalize(t('is_in_bim')) },
      (key, value) => onFieldChange('is_in_bim', +key, value),
      !writeAccess,
    ),
    booleanColumn(
      'is_testable',
      { name: capitalize(t('is_testable')) },
      (key, value) => onFieldChange('is_testable', +key, value),
      !writeAccess,
    ),
    booleanColumn(
      'is_active',
      { name: capitalize(t('active')) },
      (key, value) => onFieldChange('is_active', +key, value),
      !writeAccess,
    ),
    dateColumn('created_at', { name: t('created_at') }),
    dateColumn('updated_at', { name: t('updated_at') }),
    multiValueListSelectorColumn(
      'system_relations',
      {
        field: 'system_relations->record_id',
        name: t('system_connections'),
      },
      'record_id',
    ),
    multiValueListSelectorColumn(
      'file_containers',
      { field: 'file_containers->record_id', name: t('documents') },
      'record_id',
      writeAccess ? onShowFileContainerSelect : undefined,
    ),
  ] as ColumnDef<ISystem>[]

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'description',
    'system_status',
    'file_containers',
    'system_type',
    'system_type_unique',
    'system_type_description',
    'action',
    'location',
    'is_in_bim',
    'attachment',
    'system_mmi',
    'system_fmi_status_mapper',
    'system_fmi_group',
    'test_system_group',
    'test_work_groups',
    'disciplines',
    'contracts',
    'control_area',
    'room',
    'is_active',
    'is_testable',
    'system_connections',
    'user_defined',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
  ]

  return { defaultOrdering, legacyColumns, newColumns }
}
