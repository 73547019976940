import { MouseEvent, useContext, useState } from 'react'
import ActivityLogItemBody from 'src/components/activity-log/ActivityLogItemBody'
import ActivityLogItemHeader from 'src/components/activity-log/ActivityLogItemHeader'
import ActivityLogItemTitle from 'src/components/activity-log/ActivityLogItemTitle'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import history from 'src/history'
import {
  markLogRead,
  markLogUnread,
  removeNotificationForUser,
} from 'src/service/NotificationsService'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import { getActivityLogModalUrl } from 'src/utility/activityLogUtils'
import { classNames, openFile } from 'src/utility/utils'
import MarkNotificationAsRead from './MarkNotificationAsRead'

interface IActivityLogProps {
  data: IActivityLog
  reload: () => void
  onItemClicked: () => void
  isLastItem?: boolean
}

const NotificationItem = ({
  data,
  reload,
  onItemClicked,
  isLastItem,
}: IActivityLogProps) => {
  const modalContext = useContext(ModalContext)

  const markAsRead = async () => {
    await markLogRead(data.id)
    reload()
  }

  const markAsUnread = async () => {
    await markLogUnread(data.id)
    reload()
  }

  const removeNotification = async () => {
    await removeNotificationForUser(data.id)
    reload()
  }

  const handleItemClick = (e: MouseEvent) => {
    markAsRead()
    if (
      data.activity_type === ActivityType.DOCUMENT_UPLOAD ||
      data.activity_type === ActivityType.OMEGA_SYNC
    ) {
      openFile(data.newValue)(e)
    } else {
      history.push(getActivityLogModalUrl(data))
      modalContext.actions.checkURL()
    }
    onItemClicked()
  }

  const isRead = data.is_read || false

  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={classNames(
        'border-grey-root bg-white hover:bg-grey-lightest relative min-h-15 p-2 -z-1 flex items-center justify-between cursor-pointer',
        !isLastItem && 'border-b',
      )}
      onClick={handleItemClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={classNames(
          'flex flex-col flex-[1_0_auto] w-0 gap-1',
          isRead && 'opacity-50',
        )}
      >
        <ActivityLogItemHeader
          user={data.user}
          date={data.created_at}
          isSystemNotification={
            data.activity_type === ActivityType.SYSTEM_NOTIFICATION
          }
        />
        <ActivityLogItemTitle
          activityLog={data}
          className={classNames(isHovered && 'mr-12')}
        />
        <ActivityLogItemBody activityLog={data} className="ml-10" />
      </div>
      <MarkNotificationAsRead
        isRead={isRead}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        removeNotification={removeNotification}
        showOptionsButton={isHovered}
        className="flex-[0_0_auto]"
      />
    </div>
  )
}

export default NotificationItem
