import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CreatedByColumn,
  DocumentTypeColumn,
  TestSystemGroupsColumn,
  TestWorkGroupsColumn,
  UpdatedByColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumnWithOutEditing,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import UserIcon from 'src/components/user/UserIcon'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IFolderData } from 'src/document/types/IFolderData'
import { IFolderTreeData } from 'src/document/types/IFolderTreeData'
import { IMetaValue } from 'src/document/types/IMetaData'
import useUserAccess from 'src/hooks/useUserAccess'
import { useDomainSystemTypeGroupMetaData } from 'src/query/systemTypeGroups'
import { useTestDocumentTypes } from 'src/query/test'
import { editDocument } from 'src/service/FileContainerService'
import { IUserData } from 'src/service/OrgTypes'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import {
  dateColumn,
  editableTextColumn,
  fileColumn,
  styleClass,
  multiValueListSelectorColumn,
  textColumn,
  defaultDateColumnObject,
} from 'src/ui-elements/Table/Columns'
import {
  stepColumn,
  documentStatusColumn,
  testDocumentTypeColumn,
} from 'src/ui-elements/Table/module-columns/DocumentModuleColumns'
import { filterType, IListColumns } from 'src/ui-elements/list/ListTypes'
import useListHelper from 'src/ui-elements/list/UseListHelper'

interface IDocDetailPageProps {
  folder?: IFolderData
  currentStepId?: number
  projectLevel?: boolean
  refetch?: () => void
  onShowSystemSelectorList?: (
    systemIds: number[],
    fileContainerId: number,
  ) => void
  onShowRequirementSelectorList?: (
    requirementIds: number[],
    fileContainerId: number,
  ) => void
  readonly?: boolean
}

const useFileContainerListColumns = ({
  folder,
  projectLevel = false,
  refetch,
  onShowSystemSelectorList,
  readonly,
}: IDocDetailPageProps) => {
  const projectId = useProjectId()
  const { t } = useTranslation()

  const { getUserFilterWithReporter, getFolderFilter } = useListHelper()

  const fileContainerColumnHelper = createColumnHelper<IFileContainer>()

  const { data: userDefinedFields = [] } =
    useDomainSystemTypeGroupMetaData('FileContainer')

  const { data: testDocumentTypes = [] } = useTestDocumentTypes()

  const { writeAccess } = useUserAccess('document')

  const hasFlow = !folder || !!folder.flow_id

  const onFieldChange = (id: number, updateDoc: Partial<IFileContainer>) => {
    const doc = { ...updateDoc, id }
    editDocument(projectId, id, doc).then(refetch)
  }

  const updateMetaField = useCallback(
    (data: IMetaValue) => {
      if (data.id) {
        updateUserDefinedFieldsValue(data.id, data).then(refetch)
      }
    },
    [refetch],
  )

  const userDefinedAttributesColumns = useMemo(() => {
    return userDefinedColumns(
      userDefinedFields,
      updateMetaField,
      'meta_data',
      true,
    )
  }, [userDefinedFields, updateMetaField])

  const folderAttributesColumns = useMemo(() => {
    const required = folder?.required_fields ?? []
    const optional = folder?.optional_fields ?? []
    return userDefinedColumns(
      required.concat(optional),
      updateMetaField,
      'meta_data',
      true,
    )
  }, [folder?.optional_fields, folder?.required_fields, updateMetaField])

  const metaData = () => {
    if (!projectLevel) return [...folderAttributesColumns]
    else return [...userDefinedAttributesColumns]
  }

  const newColumns = [
    editableTextColumn(
      'record_id',
      { name: t('document_number') },
      (key, record_id) => onFieldChange(+key, { record_id }),
      readonly || hasFlow || !writeAccess,
    ),
    editableTextColumn(
      'name',
      { name: t('document_name') },
      (key, name) => onFieldChange(+key, { name }),
      readonly || !writeAccess,
    ),
    dateColumn('created_at', { name: t('created_at') }),
    dateColumn('updated_at', { name: t('updated_at') }),
    multiValueListSelectorColumn(
      'systems',
      { field: 'systems->record_id', name: t('systems') },
      'record_id',
      writeAccess ? onShowSystemSelectorList : undefined,
    ),
    fileContainerColumnHelper.accessor('status', documentStatusColumn(t)),
    fileContainerColumnHelper.accessor(
      'test_document_type',
      testDocumentTypeColumn(onFieldChange, testDocumentTypes, t),
    ),
    textColumn('current_revision.name', {
      name: capitalize(t('revision_name')),
      disabled: !hasFlow,
    }),
    dateColumn('current_revision.start_time', {
      name: capitalize(t('revision_date')),
      disabled: !hasFlow,
    }),
    fileContainerColumnHelper.accessor(
      'overdue_date',
      defaultDateColumnObject<IFileContainer>(
        'overdue_date',
        { name: t('deadline') },
        (item) => (item.current_step?.progress || 100) < 99,
        false,
        (date, item) => onFieldChange(item.id, { overdue_date: date }),
      ),
    ),
    fileColumn('current_revision.project_files', {
      field: 'current_revision.project_files->name',
      name: t('revision_files'),
      disabled: !hasFlow,
    }),
    fileColumn('project_files', {
      field: 'project_files->name',
      name: t('files'),
      disabled: !hasFlow,
    }),
    fileContainerColumnHelper.accessor('current_step', stepColumn(t)),
  ] as ColumnDef<IFileContainer>[]

  const legacyColumns: IListColumns[] = [
    DocumentTypeColumn(
      (key, value) => onFieldChange(key, { document_type_id: value }),
      false,
      false,
      readonly || !writeAccess,
    ),
    {
      name: 'folder',
      size: '150',
      id: 'folder',
      dataField: 'folder',
      sortingField: 'folder',
      filterType: filterType.DEFAULT,
      filterDataField: 'folder.name',
      filterDataValue: 'folder.id',
      filter: [],
      getFilter: getFolderFilter,
      disabled: !projectLevel,
      cell: (folder: IFolderTreeData) =>
        folder ? (
          <span className={styleClass.cell}>{folder ? folder.name : ''}</span>
        ) : (
          <span />
        ),
    },
    TestSystemGroupsColumn(),
    TestWorkGroupsColumn(),
    CreatedByColumn(),
    UpdatedByColumn(),
    {
      name: 'responsible',
      size: '150',
      id: 'responsible',
      dataField: 'responsible',
      sortingField: 'responsible',
      filterType: filterType.DEFAULT,
      filterDataField: 'responsible.firstName||responsible.lastName',
      filterDataValue: 'responsible.id',
      getFilter: getUserFilterWithReporter,
      filter: [],
      cell: (responsible: IUserData) =>
        responsible && (
          <div className={styleClass.cell}>
            <UserIcon
              userId={responsible.id}
              firstName={responsible.firstName}
              lastName={responsible.lastName}
              image_url={responsible.image_url}
            />
          </div>
        ),
    },
    DisciplineColumnWithOutEditing(),
    ContractColumn(),
    ...metaData(),
  ]

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'status',
    'document_type',
    'test_document_type',
    'current_step',
    'current_file_container_step.duedate',
    'responsible',
    'current_revision.name',
    'current_revision.start_time',
    'current_revision.project_files',
    'project_files',
    'folder',
    'requirements',
    'systems',
    'test_system_groups',
    'test_work_groups',
    'discipline',
    'contract',
    'user_defined',
    'created_by',
    'created_at',
    'updated_at',
  ]

  return { newColumns, legacyColumns, defaultOrdering }
}

export default useFileContainerListColumns
