import { ColumnDef, CellContext } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { capitalize } from 'lodash'
import * as React from 'react'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import Step from 'src/document/components/FolderDocument/Step'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IStepData } from 'src/document/types/IFlow'
import { TestDocumentStatuses, ITestDocumentType } from 'src/service/OrgTypes'
import { testDocumentStatusTypes } from 'src/service/SystemValues'
import {
  defaultFilterColumn,
  styleClass as cellStyle,
} from 'src/ui-elements/Table/Columns'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { filterType } from 'src/ui-elements/list/ListTypes'

export const testDocumentBadgeColorMapper: {
  [status in TestDocumentStatuses]: BadgeColor
} = {
  [TestDocumentStatuses.NOT_UPLOADED]: BadgeColor.YELLOW,
  [TestDocumentStatuses.UPLOADED]: BadgeColor.BLUE,
  [TestDocumentStatuses.NEEDS_REVISION]: BadgeColor.RED,
  [TestDocumentStatuses.APPROVED]: BadgeColor.GREEN,
}

export const stepColumn = (
  t: TFunction,
): ColumnDef<IFileContainer, IStepData> => {
  const field = 'current_step'
  return {
    id: field,
    size: 150,
    cell: ({ cell }) => <Step step={cell.getValue()} />,
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      name: t('step'),
      field: 'current_step.name',
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
    },
  }
}

export const documentStatusColumn = (
  t: TFunction,
): ColumnDef<IFileContainer, TestDocumentStatuses> => {
  const field = 'status'
  return {
    ...defaultFilterColumn<IFileContainer>(field, {
      name: capitalize(t('status')),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            testDocumentStatusTypes(t).map((status) => {
              return {
                value: status.id,
                id: status.id,
                name: status.name,
                active: false,
              }
            }),
          )
        })
      },
    }),
    size: 130,
    cell: (
      props: CellContext<IFileContainer, TestDocumentStatuses | undefined>,
    ) => {
      const value = props.getValue()
      if (value === null || value === undefined) return null
      return (
        <div className={'px-1'}>
          <Badge color={testDocumentBadgeColorMapper[value]} text={t(value)} />
        </div>
      )
    },
  }
}

export const testDocumentTypeColumn = (
  onFieldChange: (id: number, updateDoc: Partial<IFileContainer>) => void,
  testDocumentTypes: ITestDocumentType[],
  t: TFunction,
): ColumnDef<IFileContainer, ITestDocumentType> => {
  return {
    ...defaultFilterColumn<IFileContainer>('test_document_type', {
      name: capitalize(t('document_type')),
      getFilter: () =>
        Promise.resolve(
          testDocumentTypes.map((value) => ({
            value: `${value.id}`,
            name: value.document_name,
            active: true,
          })),
        ),
    }),
    size: 130,
    cell: ({
      cell,
      row,
    }: CellContext<IFileContainer, ITestDocumentType | undefined>) => (
      <TableSelectorEditor
        rowId={row.original['id']}
        selected={cell.getValue()}
        getData={() => testDocumentTypes}
        dataFields={['document_name']}
        isUserSelector={false}
        onDataSelected={(value) =>
          onFieldChange(row.original['id'], {
            test_document_type_id: +value.id,
          })
        }
        displayContent={
          <span className={cellStyle.cell}>
            {cell.getValue()?.['document_name']}
          </span>
        }
        disableEdit={false}
      />
    ),
  }
}
